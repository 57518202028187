import { ref, watch, computed } from '@vue/composition-api'
import {
  useTableComponent, useCommenSettings, useAlert,
} from '@/libs/mixins/index'
import store from '@/store'
import router from '@/router'

export const useDeviceSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const ui = {}

  const purchaseTimeRange = ref(null)
  const searchBrand = ref(null)
  const brandsOptions = ref([])
  const placesOptions = ref([])

  const getMetaListData = () => store.dispatch('api/getMetaList')

  const blankUserInfo = {
    id: 1,
    last_action_time: null,
    name: null,
  }

  const blankBrandInfo = {
    id: null,
    name: null,
    description: null,
  }

  const blankDeviceInfo = {
    name: null,
  }

  const blankCPUData = {
    name: null,
    serialNumber: null,
    health: 0,
    pc_id: null,
    purchase_date: null,
    brand_id: null,
    description: '',
  }

  const blankMemoryData = {
    name: null,
    serialNumber: null,
    health: 0,
    pc_id: null,
    size: 0,
    purchase_date: null,
    brand_id: null,
    description: '',
  }

  const blankDiskData = {
    name: null,
    serialNumber: null,
    health: 0,
    pc_id: null,
    size: 0,
    purchase_date: null,
    brand_id: null,
    description: '',
  }

  const blankChartData = {
    series: [0],
    options: {
      grid: {
        show: false,
        padding: {
          left: -15,
          right: -15,
          top: -12,
          bottom: -15,
        },
      },
      colors: ['#24c9ff'],
      plotOptions: {
        radialBar: {
          hollow: {
            size: '22%',
          },
          track: {
            background: '#e9ecef',
          },
          dataLabels: {
            showOn: 'always',
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },
      stroke: {
        lineCap: 'round',
      },
    },
  }

  const resolveChartDataColor = health => {
    if (health <= 10) return '#ff5b52'
    if (health <= 50) return '#ffbb53'
    if (health < 100) return '#24c9ff'
    if (health === 100) return '#37deb2'
    return '#24c9ff'
  }

  return {
    ui,

    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
    getMetaListData,
    resolveChartDataColor,

    purchaseTimeRange,
    searchBrand,
    brandsOptions,
    placesOptions,

    blankUserInfo,
    blankBrandInfo,
    blankDeviceInfo,
    blankCPUData,
    blankMemoryData,
    blankDiskData,
    blankChartData,
  }
}

export const useDeviceList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    refetchData,
  } = useTableComponent()

  const {
    useHttpCodeAlert,
  } = useAlert()

  const {
    brandsOptions,
  } = useDeviceSetting()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '類別', key: 'type', sortable: true, searchable: true, select: true,
    },
    {
      label: '設備名稱', key: 'name', sortable: true, searchable: true, select: true,
    },
    {
      label: '說明', key: 'description', sortable: true, searchable: true, select: true,
    },
    {
      label: '品牌', key: 'brand', sortable: true, searchable: true, select: true,
    },
    {
      label: '地點', key: 'place', sortable: true, searchable: true, select: true,
    },
    {
      label: '使用者', key: 'user', sortable: true, searchable: true, select: true,
    },
    {
      label: '購買日期', key: 'purchase_date', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const timeRange = ref(null)
  const deviceTypeList = ref({})
  const deviceTypeOptions = ref([])

  const setDeviceDelete = (...arg) => store.dispatch('admin-device/setDeviceDelete', ...arg)
  const setDeviceCreate = (...arg) => store.dispatch('admin-device/setDeviceCreate', ...arg)
  const setDeviceUpdate = (...arg) => store.dispatch('admin-device/setDeviceUpdate', ...arg)

  const getDeviceListData = (ctx, callback) => {
    // if (store.state.app.ability.user === 'none') {
    //   router.replace({ name: 'admin-home' })
    //   return
    // }

    // 整理timeRange
    // let resolveTimeRange = null
    // if (timeRange.value) {
    //   resolveTimeRange = timeRange.value.split(' 至 ')
    //   const [start, end] = resolveTimeRange
    //   if (end) {
    //     resolveTimeRange = `${start} to ${end}`
    //   } else resolveTimeRange = `${start}`
    // }

    store.dispatch('admin-device/getDeviceList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      // range: resolveTimeRange,
      // filters: resolveFilters,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        const deviceType = response.data.data.device_type
        deviceTypeList.value = deviceType
        const keys = Object.keys(deviceType)
        deviceTypeOptions.value = keys.map(key => {
          const option = {
            label: deviceType[key],
            value: key,
          }
          return option
        })
        callback(data)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  // (搜尋)類別
  const resolveDeviceType = type => {
    const foundItem = deviceTypeOptions.value.find(item => item.value === type)
    if (foundItem) return foundItem
    return { label: type, value: type }
  }

  watch([currentPage, perPage, searchQuery, timeRange], () => {
    refetchData()
  })

  return {
    isBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    deviceTypeList,
    deviceTypeOptions,
    brandsOptions,
    refetchData,
    refetchTable,

    setDeviceDelete,
    setDeviceCreate,
    setDeviceUpdate,
    getDeviceListData,
    resolveDeviceType,
    useHttpCodeAlert,
  }
}

export const useDevicePCList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    refetchData,
  } = useTableComponent()

  const {
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  const {
    blankUserInfo,
  } = useDeviceSetting()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: 'IP位置', key: 'assigned_ip', sortable: true, searchable: true, select: true,
    },
    {
      label: '說明', key: 'description', sortable: true, searchable: true, select: true,
    },
    {
      label: 'CPU型號', key: 'cpu_model', sortable: true, searchable: true, select: true,
    },
    {
      label: '記憶體大小', key: 'memory_size', sortable: true, searchable: true, select: true,
    },
    {
      label: '硬碟大小', key: 'hard_disk_size', sortable: true, searchable: true, select: true,
    },
    {
      label: '使用者', key: 'user', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const blankPCData = {
    assigned_ip: null,
    cpu: [],
    description: null,
    hard_disk_size: 0,
    memory_size: 0,
  }

  const setDevicePCDelete = (...arg) => store.dispatch('admin-device/setDevicePCDelete', ...arg)
  const setDevicePCCreate = (...arg) => store.dispatch('admin-device/setDevicePCCreate', ...arg)
  const setDevicePCUpdate = (...arg) => store.dispatch('admin-device/setDevicePCUpdate', ...arg)

  const getDevicePCListData = (ctx, callback) => {
    // if (store.state.app.ability.user === 'none') {
    //   router.replace({ name: 'admin-home' })
    //   return
    // }

    store.dispatch('admin-device/getDevicePCList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            id: item.id,
            ...syncObject(blankPCData, item),
            user_info: item.user_info ? syncObject(blankUserInfo, item.user_info) : syncObject(blankUserInfo, {}),
            updated_at: item.updated_at,
          }
          return resolve
        })
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  return {
    isBusy,
    blankPCData,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    refetchData,
    refetchTable,

    setDevicePCDelete,
    setDevicePCCreate,
    setDevicePCUpdate,
    getDevicePCListData,
    useHttpCodeAlert,
  }
}

export const useDevicePCEdit = () => {
  const {
    syncObject,
  } = useCommenSettings()

  const {
    blankUserInfo,
    blankCPUData,
    blankMemoryData,
    blankDiskData,
    blankChartData,
    blankBrandInfo,
    resolveChartDataColor,
  } = useDeviceSetting()

  const {
    useHttpCodeAlert,
  } = useAlert()

  const isBusy = ref(false)
  const deviceData = ref(null)
  const deviceArea = ref({ label: 'CPU', value: 'cpu' })
  const deviceAreaOptions = [
    { label: 'CPU', value: 'cpu' },
    { label: '記憶體', value: 'memory' },
    { label: '硬碟', value: 'disk' },
  ]

  const blankDeviceInfo = {
    id: null,
    name: null,
    description: '',
    purchase_date: null,
    type: null,
    // brand_id: null
    // uuid: null,
    // serialNumber: null,
  }

  const blankDeviceData = {
    assigned_ip: null,
    device_id: null,
    hard_disk_size: 0,
    memory_size: 0,
    description: '',
    updated_at: null,
  }

  const getDevicePCData = () => {
    isBusy.value = true
    store.dispatch('admin-device/getDevicePCData', {
      pc_id: router.currentRoute.params.id,
      _: Date.now(),
    })
      .then(response => {
        const { data } = response.data
        const resolvedData = {
          id: data.id,
          ...syncObject(blankDeviceData, data),
          device_info: data.device_info ? syncObject(blankDeviceInfo, data.device_info) : syncObject(blankDeviceInfo, {}),
          user_info: data.user_info ? syncObject(blankUserInfo, data.user_info) : syncObject(blankUserInfo, {}),
        }
        resolvedData.cpu = data.cpu.map(item => {
          const resolve = {
            id: item.id,
            ...syncObject(blankCPUData, item),
            brand_info: item.brand_info ? syncObject(blankBrandInfo, item.brand_info) : syncObject(blankBrandInfo, {}),
            chartDataAll: JSON.parse(JSON.stringify(blankChartData)),
          }
          resolve.chartDataAll.series[0] = resolve.health
          resolve.chartDataAll.options.colors[0] = resolveChartDataColor(resolve.health)
          return resolve
        })
        resolvedData.memory = data.memories.map(item => {
          const resolve = {
            id: item.id,
            ...syncObject(blankMemoryData, item),
            brand_info: item.brand_info ? syncObject(blankBrandInfo, item.brand_info) : syncObject(blankBrandInfo, {}),
            chartDataAll: JSON.parse(JSON.stringify(blankChartData)),
          }
          resolve.chartDataAll.series[0] = resolve.health
          resolve.chartDataAll.options.colors[0] = resolveChartDataColor(resolve.health)
          return resolve
        })
        resolvedData.disk = data.hard_disk.map(item => {
          const resolve = {
            id: item.id,
            ...syncObject(blankDiskData, item),
            brand_info: item.brand_info ? syncObject(blankBrandInfo, item.brand_info) : syncObject(blankBrandInfo, {}),
            chartDataAll: JSON.parse(JSON.stringify(blankChartData)),
          }
          resolve.chartDataAll.series[0] = resolve.health
          resolve.chartDataAll.options.colors[0] = resolveChartDataColor(resolve.health)
          return resolve
        })
        deviceData.value = resolvedData
        isBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  return {
    isBusy,
    deviceArea,
    deviceData,
    deviceAreaOptions,
    getDevicePCData,
    useHttpCodeAlert,
  }
}

export const useDeviceCPUList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    // dataMeta,
    resolveRangeTime,
  } = useTableComponent()

  const {
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  const {
    purchaseTimeRange,
    searchBrand,
    blankBrandInfo,
    blankDeviceInfo,
    blankCPUData,
    blankChartData,
    resolveChartDataColor,
  } = useDeviceSetting()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '使用', key: 'pc_id', sortable: false, searchable: true, select: true,
    },
    {
      label: '名稱', key: 'name', sortable: true, searchable: true, select: true,
    },
    {
      label: '說明', key: 'description', sortable: true, searchable: true, select: true,
    },
    {
      label: '品牌', key: 'brand', sortable: true, searchable: true, select: true,
    },
    {
      label: '健康度', key: 'health', sortable: true, searchable: true, select: true,
    },
    {
      label: '購買日期', key: 'purchase_date', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '創建時間', key: 'created_at', sortable: true, searchable: true, select: false,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const unUse = ref(true)
  const tableData = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = totalNum.value
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * currentPage.value > totalNum.value ? totalNum.value : perPage.value * (currentPage.value - 1) + perPage.value,
      of: totalNum.value,
    }
  })

  const setDeviceCPUDelete = (...arg) => store.dispatch('admin-device/setDeviceCPUDelete', ...arg)
  const setDeviceCPUCreate = (...arg) => store.dispatch('admin-device/setDeviceCPUCreate', ...arg)
  const setDeviceCPUUpdate = (...arg) => store.dispatch('admin-device/setDeviceCPUUpdate', ...arg)

  const getDeviceCPUListData = () => {
    // ctx, callback
    isBusy.value = true

    // 整理filters
    let resolveFilters = ''

    if (!unUse.value) {
      resolveFilters = 'pc_id: null'
    }

    if (searchBrand.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};brand_id: ${searchBrand.value}`
      } else resolveFilters = `brand_id: ${searchBrand.value}`
    }

    if (purchaseTimeRange.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};purchase_date: ${purchaseTimeRange.value ? resolveRangeTime(purchaseTimeRange.value) : null}`
      } else resolveFilters = `purchase_date: ${purchaseTimeRange.value ? resolveRangeTime(purchaseTimeRange.value) : null}`
    }

    store.dispatch('admin-device/getDeviceCPUList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      filters: resolveFilters,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            id: item.id,
            ...syncObject(blankCPUData, item),
            brand_info: item.brand_info ? syncObject(blankBrandInfo, item.brand_info) : syncObject(blankBrandInfo, {}),
            device_info: item.device_info ? syncObject(blankDeviceInfo, item.device_info) : syncObject(blankDeviceInfo, {}),
            chartDataAll: JSON.parse(JSON.stringify(blankChartData)),
            updated_at: item.updated_at,
            created_at: item.created_at,
          }
          resolve.chartDataAll.series[0] = resolve.health
          resolve.chartDataAll.options.colors[0] = resolveChartDataColor(resolve.health)
          return resolve
        })
        // callback(resolveData)
        tableData.value = resolveData
        totalNum.value = total
        isBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else getDeviceCPUListData()
  }

  watch([currentPage, perPage, searchQuery, searchBrand, purchaseTimeRange], () => {
    getDeviceCPUListData()
  })

  return {
    isBusy,
    unUse,
    tableData,
    blankCPUData,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    searchBrand,
    purchaseTimeRange,
    refetchTable,

    setDeviceCPUDelete,
    setDeviceCPUCreate,
    setDeviceCPUUpdate,
    getDeviceCPUListData,
    useHttpCodeAlert,
  }
}

export const useDeviceMemoryList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    // dataMeta,
    resolveRangeTime,
  } = useTableComponent()

  const {
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  const {
    purchaseTimeRange,
    searchBrand,
    blankDeviceInfo,
    blankBrandInfo,
    blankMemoryData,
    blankChartData,
    resolveChartDataColor,
  } = useDeviceSetting()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '使用', key: 'pc_id', sortable: false, searchable: true, select: true,
    },
    {
      label: '名稱', key: 'name', sortable: true, searchable: true, select: true,
    },
    {
      label: '說明', key: 'description', sortable: true, searchable: true, select: true,
    },
    {
      label: '品牌', key: 'brand', sortable: true, searchable: true, select: true,
    },
    {
      label: '內存大小', key: 'size', sortable: true, searchable: true, select: true,
    },
    {
      label: '健康度', key: 'health', sortable: true, searchable: true, select: true,
    },
    {
      label: '購買日期', key: 'purchase_date', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '創建時間', key: 'created_at', sortable: true, searchable: true, select: false,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const unUse = ref(true)
  const tableData = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = totalNum.value
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * currentPage.value > totalNum.value ? totalNum.value : perPage.value * (currentPage.value - 1) + perPage.value,
      of: totalNum.value,
    }
  })

  const setDeviceMemoryDelete = (...arg) => store.dispatch('admin-device/setDeviceMemoryDelete', ...arg)
  const setDeviceMemoryCreate = (...arg) => store.dispatch('admin-device/setDeviceMemoryCreate', ...arg)
  const setDeviceMemoryUpdate = (...arg) => store.dispatch('admin-device/setDeviceMemoryUpdate', ...arg)

  const getDeviceMemoryListData = () => {
    // ctx, callback
    // if (store.state.app.ability.user === 'none') {
    //   router.replace({ name: 'admin-home' })
    //   return
    // }
    isBusy.value = true

    // 整理filters
    let resolveFilters = ''

    if (!unUse.value) {
      resolveFilters = 'pc_id: null'
    }

    if (searchBrand.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};brand_id: ${searchBrand.value}`
      } else resolveFilters = `brand_id: ${searchBrand.value}`
    }

    if (purchaseTimeRange.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};purchase_date: ${purchaseTimeRange.value ? resolveRangeTime(purchaseTimeRange.value) : null}`
      } else resolveFilters = `purchase_date: ${purchaseTimeRange.value ? resolveRangeTime(purchaseTimeRange.value) : null}`
    }

    store.dispatch('admin-device/getDeviceMemoryList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      filters: resolveFilters,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            id: item.id,
            ...syncObject(blankMemoryData, item),
            brand_info: item.brand_info ? syncObject(blankBrandInfo, item.brand_info) : syncObject(blankBrandInfo, {}),
            device_info: item.device_info ? syncObject(blankDeviceInfo, item.device_info) : syncObject(blankDeviceInfo, {}),
            chartDataAll: JSON.parse(JSON.stringify(blankChartData)),
            updated_at: item.updated_at,
            created_at: item.created_at,
          }
          resolve.chartDataAll.series[0] = resolve.health
          resolve.chartDataAll.options.colors[0] = resolveChartDataColor(resolve.health)
          return resolve
        })
        // callback(resolveData)
        tableData.value = resolveData
        totalNum.value = total
        isBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else getDeviceMemoryListData()
  }

  watch([currentPage, perPage, searchQuery, searchBrand, purchaseTimeRange], () => {
    getDeviceMemoryListData()
  })

  return {
    isBusy,
    unUse,
    tableData,
    blankMemoryData,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    searchBrand,
    purchaseTimeRange,
    refetchTable,

    setDeviceMemoryDelete,
    setDeviceMemoryCreate,
    setDeviceMemoryUpdate,
    getDeviceMemoryListData,
    useHttpCodeAlert,
  }
}

export const useDeviceDiskList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    // dataMeta,
    resolveRangeTime,
  } = useTableComponent()

  const {
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  const {
    purchaseTimeRange,
    searchBrand,
    blankDeviceInfo,
    blankBrandInfo,
    blankDiskData,
    blankChartData,
    resolveChartDataColor,
  } = useDeviceSetting()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '使用', key: 'pc_id', sortable: false, searchable: true, select: true,
    },
    {
      label: '名稱', key: 'name', sortable: true, searchable: true, select: true,
    },
    {
      label: '說明', key: 'description', sortable: true, searchable: true, select: true,
    },
    {
      label: '品牌', key: 'brand', sortable: true, searchable: true, select: true,
    },
    {
      label: '內存大小', key: 'size', sortable: true, searchable: true, select: true,
    },
    {
      label: '健康度', key: 'health', sortable: true, searchable: true, select: true,
    },
    {
      label: '購買日期', key: 'purchase_date', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次更新', key: 'updated_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '創建時間', key: 'created_at', sortable: true, searchable: true, select: false,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const unUse = ref(true)
  const tableData = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = totalNum.value
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * currentPage.value > totalNum.value ? totalNum.value : perPage.value * (currentPage.value - 1) + perPage.value,
      of: totalNum.value,
    }
  })

  const setDeviceDiskDelete = (...arg) => store.dispatch('admin-device/setDeviceDiskDelete', ...arg)
  const setDeviceDiskCreate = (...arg) => store.dispatch('admin-device/setDeviceDiskCreate', ...arg)
  const setDeviceDiskUpdate = (...arg) => store.dispatch('admin-device/setDeviceDiskUpdate', ...arg)

  const getDeviceDiskListData = () => {
    // ctx, callback
    // if (store.state.app.ability.user === 'none') {
    //   router.replace({ name: 'admin-home' })
    //   return
    // }
    isBusy.value = true

    // 整理filters
    let resolveFilters = ''

    if (!unUse.value) {
      resolveFilters = 'pc_id: null'
    }

    if (searchBrand.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};brand_id: ${searchBrand.value}`
      } else resolveFilters = `brand_id: ${searchBrand.value}`
    }

    if (purchaseTimeRange.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};purchase_date: ${purchaseTimeRange.value ? resolveRangeTime(purchaseTimeRange.value) : null}`
      } else resolveFilters = `purchase_date: ${purchaseTimeRange.value ? resolveRangeTime(purchaseTimeRange.value) : null}`
    }

    store.dispatch('admin-device/getDeviceDiskList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      filters: resolveFilters,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            id: item.id,
            ...syncObject(blankDiskData, item),
            brand_info: item.brand_info ? syncObject(blankBrandInfo, item.brand_info) : syncObject(blankBrandInfo, {}),
            device_info: item.device_info ? syncObject(blankDeviceInfo, item.device_info) : syncObject(blankDeviceInfo, {}),
            chartDataAll: JSON.parse(JSON.stringify(blankChartData)),
            updated_at: item.updated_at,
            created_at: item.created_at,
          }
          resolve.chartDataAll.series[0] = resolve.health
          resolve.chartDataAll.options.colors[0] = resolveChartDataColor(resolve.health)
          return resolve
        })
        // callback(resolveData)
        tableData.value = resolveData
        totalNum.value = total
        isBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else getDeviceDiskListData()
  }

  watch([currentPage, perPage, searchQuery, searchBrand, purchaseTimeRange], () => {
    getDeviceDiskListData()
  })

  return {
    isBusy,
    unUse,
    tableData,
    blankDiskData,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    searchBrand,
    purchaseTimeRange,
    refetchTable,

    setDeviceDiskDelete,
    setDeviceDiskCreate,
    setDeviceDiskUpdate,
    getDeviceDiskListData,
    useHttpCodeAlert,
  }
}
