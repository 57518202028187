import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // (查詢)地點列表
    getPlaceList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/place', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)單一管理者
    getPlaceData(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/place/${resolveData.place_id}`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)地點
    setPlaceCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/place', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)地點
    setPlaceUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/place/${resolveData.place_id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)地點
    setPlaceDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/place/${resolveData.place_id}`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
