<template>
  <b-modal
    id="edit-modal"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
    @hidden="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        新增設備
      </h4>
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- 設備名稱 -->
        <validation-provider
          #default="{ errors }"
          name="設備名稱"
          rules="required"
        >
          <b-form-group label-for="name">
            <template #label>
              <label class="mb-0">
                設備名稱
                <span class="text-danger">*</span>
              </label>
            </template>

            <b-form-input
              id="name"
              v-model="deviceData.name"
              autofocus
              :state="errors.length > 0 ? false : null"
              trim
              placeholder="請輸入設備名稱"
            />

            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- 設備類別 -->
        <validation-provider
          name="類別"
          rules="required"
        >
          <b-form-group label-for="name">
            <template #label>
              <label class="mb-0">
                類別
                <span class="text-danger">*</span>
              </label>
            </template>

            <v-select
              v-model=" deviceData.type"
              :options="deviceTypeOptions"
              :clearable="false"
              :reduce="option => option.value"
              placeholder="請選擇類型"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  沒有<em>{{ search }}</em> 的相關結果
                </template>

                <div v-else>
                  <small>暫無資料</small>
                </div>
              </template>
            </v-select>
          </b-form-group>
        </validation-provider>

        <div>
          <component
            :is="componentType"
            :device-data-item="deviceData"
          />
        </div>

        <!-- 地點 -->
        <b-form-group label-for="place_id">
          <template #label>
            <div class="d-flex justify-content-between align-items-center">
              <label class="mb-0">
                地點
              </label>

              <span>
                <div
                  v-b-tooltip.hover.v-secondary
                  title="地點設定"
                  class="d-flex align-items-center actions-link-btn-sm"
                  @click="() => { $refs.placeExplorerEditModal.getData(null) }"
                >
                  <!-- @click="() => { $refs.brandExplorerMainModal.getData() }" -->
                  <b-img
                    src="/admin/images/table/settings.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>
              </span>
            </div>
          </template>

          <v-select
            v-model="deviceData.place_id"
            :options="placesOptions"
            :clearable="false"
            label="name"
            :reduce="option => option.id"
            placeholder="請選擇地點"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                沒有<em>{{ search }}</em> 的相關結果
              </template>

              <div v-else>
                <small>暫無資料</small>
              </div>
            </template>
          </v-select>
        </b-form-group>

        <!-- 使用者 -->
        <b-form-group
          label="使用者"
          label-for="label"
        >
          <b-input-group>
            <b-form-input
              id="user_info"
              v-model="deviceData.user_info.name"
              name="device-user-info"
              disabled
              placeholder="請選擇使用者"
              class="blank-selected-input"
            />
            <b-input-group-append>
              <b-button
                variant="outline-primary"
                @click="selectedUserModal"
              >
                選擇
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <!-- 購買日期 -->
        <b-form-group label-for="purchase_date">
          <template #label>
            <label class="mb-0">
              購買日期
            </label>
          </template>

          <flat-pickr
            v-model="deviceData.purchase_date"
            class="date-form-control form-control"
            :config="dateConfig"
            placeholder="YYYY-MM-DD"
          />
        </b-form-group>

        <div>
          <label>設備說明</label>
          <b-form-textarea
            v-model="deviceData.description"
            placeholder="請輸入設備說明"
            :state="deviceData.description.length <= maxChar ? null : false"
            rows="5"
          />
          <small
            class="textarea-counter-value float-right"
            :class="deviceData.description.length > maxChar ? 'bg-danger' : ''"
          >
            <span class="char-count">{{ deviceData.description.length }}</span> / {{ maxChar }}
          </small>
        </div>
      </b-form>

      <!-- {{ deviceData }} -->
    </validation-observer>

    <user-explorer-modal
      ref="userExplorerEditModal"
      :is-edit-auth="false"
      user-explorer-id="userExplorerEditModal"
      :is-hiden-emit="false"
      :use-callback-data="true"
      @call-back-data="callbackUserExplorer"
    />

    <place-explorer-modal
      ref="placeExplorerEditModal"
      place-explorer-id="placeExplorerEditModal"
      :is-hiden-emit="true"
      :use-callback-data="false"
      @emit-hidden-function="resetMetaList"
    />

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>

  </b-modal>
</template>

<script>
// API
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'

// UI
import {
  BForm, BFormGroup, BFormInput, VBToggle, BButton, BSpinner, BFormInvalidFeedback, BFormTextarea,
  BInputGroup, BInputGroupAppend, BImg, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import language from 'flatpickr/dist/l10n/zh-tw'
import flatPickr from 'vue-flatpickr-component'

// Component
import formValidation from '@core/comp-functions/forms/form-validation'
import userExplorerModal from '@/layouts/components/Modal/user-choice-modal/userModal.vue'
import { useDeviceList, useDeviceSetting } from '../useDevice'
import { useAlert } from '@/libs/mixins/index'
import placeExplorerModal from '@/layouts/components/Modal/place-modal/placeModal.vue'
import componentBase from './components/base/Components.vue'
import componentPc from './components/pc/Components.vue'
// import store from '@/store'

export default {
  components: {
    BImg,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
    BFormTextarea,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    flatPickr,

    ValidationProvider,
    ValidationObserver,

    componentBase,
    componentPc,
    placeExplorerModal,
    userExplorerModal,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  props: {
    deviceTypeOptions: {
      type: Array,
      required: true,
    },
    deviceItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      missingFields: [],
      maxChar: 255,
    }
  },
  computed: {
    componentType() {
      if (this.deviceData.type === 'pc') return 'component-pc'
      return 'component-base'
    },
  },
  methods: {
    // (觸發)選擇使用者
    selectedUserModal() {
      setTimeout(() => { this.$refs.userExplorerEditModal.getData(this.deviceData) }, 200)
    },

    // (回調)選擇的使用者
    callbackUserExplorer(user) {
      this.deviceData.user_id = user.id
      this.deviceData.user_info = user
    },

    // (重新)獲取Meta
    resetMetaList() {
      this.getMetaListData()
        .then(response => {
          const { places } = response.data.data
          this.placesOptions = places
          const findObject = places.find(el => el.id === this.deviceData.place_id)

          if (findObject === undefined) {
            this.deviceData.place_id = null
          }
        })
    },

    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetDeviceData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide('edit-modal')
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      // 判斷必填欄位是否填寫
      const requiredFields = {
        name: '設備名稱',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.deviceData[Object.keys(requiredFields)[i]] === null || this.deviceData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            lable: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.deviceData.description.length > this.maxChar) {
        this.useAlertToast(false, '設備說明字數超過上限')
        return
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].lable}不得為空`)
        return
      }

      const resolveData = {
        name: this.deviceData.name,
        description: this.deviceData.description,
        type: this.deviceData.type,
        purchase_date: this.deviceData.purchase_date,
        user_id: this.deviceData.user_id,
        brand_id: this.deviceData.extends.brand_id,
        place_id: this.deviceData.place_id,
        pc_info: {
          assigned_ip: this.deviceData.extends.pc_info.assigned_ip,
        },
      }

      this.isBusy = true

      this.setDeviceUpdate({
        device_id: this.deviceItem.id,
        data: resolveData,
      })
        .then(response => {
          this.useHttpCodeAlert(response)
          this.$emit('refetch-data')
          this.resetDeviceData()
          this.$nextTick(() => {
            this.$bvModal.hide('edit-modal')
          })
          this.isBusy = false
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
          this.isBusy = false
        })
    },

    // (獲取)資料
    getData() {
      const resolveData = {
        name: this.deviceItem.name,
        description: this.deviceItem.description ? this.deviceItem.description : '',
        type: this.deviceItem.type ? this.deviceItem.type : 'pc',
        purchase_date: this.deviceItem.purchase_date,
        user_id: this.deviceItem.user_id,
        user_info: {
          id: this.deviceItem.user_id,
          name: this.deviceItem.user_id ? this.deviceItem.user_info.name : null,
        },
        place_id: this.deviceItem.place_id,
        place_info: {
          id: this.deviceItem.place_id,
          name: this.deviceItem.place_id ? this.deviceItem.place_info.name : null,
        },
        extends: {
          brand_id: this.deviceItem.brand_id,
          pc_info: {
            assigned_ip: this.deviceItem.pc_info ? this.deviceItem.pc_info.assigned_ip : null,
            description: '',
          },
        },
      }
      this.resetMetaList()
      this.deviceData = resolveData
      this.$bvModal.show('edit-modal')
    },
  },
  setup(props) {
    localize('tw')

    const {
      isBusy,
      setDeviceUpdate,
    } = useDeviceList()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const {
      placesOptions,
      getMetaListData,
    } = useDeviceSetting()

    const blankDeviceData = {
      name: null,
      description: '',
      type: 'pc',
      purchase_date: null,
      user_id: null,
      user_info: {
        id: null,
        name: null,
      },
      place_id: null,
      place_info: {
        id: null,
        name: null,
      },
      extends: {
        brand_id: null,
        // brand_info: syncObject(blankBrandInfo, {}),
        pc_info: {
          assigned_ip: null,
          description: '',
        },
      },
    }

    const deviceData = ref(JSON.parse(JSON.stringify(blankDeviceData)))
    deviceData.value.type = props.deviceTypeOptions[0].value

    const resetDeviceData = () => {
      deviceData.value = JSON.parse(JSON.stringify(blankDeviceData))
      isBusy.value = false
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetDeviceData)

    const dateConfig = {
      locale: language.zh_tw,
      dateFormat: 'Y-m-d',
      allowInput: true,
      static: true,
    }

    return {
      isBusy,

      deviceData,
      dateConfig,
      setDeviceUpdate,
      useAlertToast,
      useHttpCodeAlert,

      resetDeviceData,
      refFormObserver,
      getValidationState,
      resetForm,

      placesOptions,
      getMetaListData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}
</style>
