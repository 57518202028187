<template>
  <b-modal
    :id="updateModalId"
    no-close-on-backdrop
    ok-title="確認"
    centered
    header-bg-variant="primary"
    ok-only
    @hidden="handleHide"
  >
    <template #modal-title>
      <h4 class="m-0 model-header">
        {{ selectedItem.id ? '編輯使用者' : '新增使用者' }}
      </h4>
    </template>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <!-- 狀態 -->
        <b-form-group label-for="status">
          <template #label>
            <div class="d-flex justify-content-between align-items-center">
              <label class="mb-0">
                狀態
                <span class="text-danger">*</span>
              </label>
            </div>
          </template>

          <v-select
            v-model="userData.status"
            :options="statusOptions"
            :clearable="false"
            :reduce="option => option.value"
            placeholder="請選擇狀態"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                沒有<em>{{ search }}</em> 的相關結果
              </template>

              <div v-else>
                <small>暫無資料</small>
              </div>
            </template>
          </v-select>
        </b-form-group>

        <!-- 部門 -->
        <b-form-group label-for="department_id">
          <template #label>
            <div class="d-flex justify-content-between align-items-center">
              <label class="mb-0">
                部門
                <span class="text-danger">*</span>
              </label>

              <span>
                <div
                  v-b-tooltip.hover.v-secondary
                  title="部門設定"
                  class="d-flex align-items-center actions-link-btn-sm"
                  @click="() => { $refs.departmentExplorerModal.getData(null) }"
                >
                  <!-- @click="() => { $refs.departmentExplorerMainModal.getData() }" -->
                  <b-img
                    src="/admin/images/table/settings.svg"
                    class="actions-link-btn-image"
                    rounded
                  />
                </div>
              </span>
            </div>
          </template>

          <v-select
            v-model="userData.department_id"
            :options="departmentsOptions"
            :clearable="false"
            label="name"
            :reduce="option => option.id"
            placeholder="請選擇部門"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                沒有<em>{{ search }}</em> 的相關結果
              </template>

              <div v-else>
                <small>暫無資料</small>
              </div>
            </template>
          </v-select>
        </b-form-group>

        <!-- 使用者名稱 -->
        <validation-provider
          #default="{ errors }"
          name="使用者名稱"
          rules="required"
        >
          <b-form-group label-for="name">
            <template #label>
              <label class="mb-0">
                使用者名稱
                <span class="text-danger">*</span>
              </label>
            </template>
            <b-form-input
              id="name"
              v-model="userData.name"
              :state="errors.length > 0 ? false : null"
              trim
              :placeholder="selectedItem.name ? selectedItem.name : '請輸入使用者名稱'"
            />

            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- 使用者帳號 -->
        <validation-provider
          #default="{ errors }"
          name="使用者帳號"
          rules="required"
        >
          <b-form-group label-for="account">
            <template #label>
              <label class="mb-0">
                使用者帳號
                <span class="text-danger">*</span>
              </label>
            </template>
            <b-form-input
              id="account"
              v-model="userData.account"
              :state="errors.length > 0 ? false : null"
              trim
              :placeholder="selectedItem.account ? selectedItem.account : '請輸入使用者帳號'"
            />

            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <!-- 密碼設定(新增模式) -->
        <validation-provider
          v-if="!selectedItem.id"
          #default="{ errors }"
          rules="required"
          name="password"
        >

          <b-form-group label-for="userData-password">
            <template #label>
              <div class="d-flex justify-content-between align-items-center">
                <label class="mb-0">
                  密碼設定
                  <span class="text-danger">*</span>
                </label>

                <span>
                  <div
                    v-b-tooltip.hover.v-secondary
                    title="密碼產生器"
                    class="d-flex align-items-center actions-link-btn"
                    @click="() => { $refs.randomGenerator.getData() }"
                  >
                    <b-img
                      src="/admin/images/table/setting.svg"
                      class="actions-link-btn-image"
                      rounded
                    />
                  </div>
                </span>
              </div>
            </template>
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="userData-password"
                v-model="userData.password"
                :type="passwordFieldType"
                :state="errors.length > 0 ? false : null"
                class="form-control-merge"
                name="userData-password"
                placeholder="請輸入管理者密碼"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePassword"
                />
              </b-input-group-append>
            </b-input-group>

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- 變更密碼(編輯模式) -->
        <validation-provider
          v-if="selectedItem.id"
          #default="{ errors }"
          name="password"
        >

          <b-form-group label-for="userData-password">
            <!-- <template #label>
              <label class="mb-0">
                變更密碼
              </label>
            </template> -->

            <template #label>
              <div class="d-flex justify-content-between align-items-center">
                <label class="mb-0">
                  變更密碼
                </label>

                <span>
                  <div
                    v-b-tooltip.hover.v-secondary
                    title="密碼產生器"
                    class="d-flex align-items-center actions-link-btn"
                    @click="() => { $refs.randomGenerator.getData() }"
                  >
                    <b-img
                      src="/admin/images/table/setting.svg"
                      class="actions-link-btn-image"
                      rounded
                    />
                  </div>
                </span>
              </div>
            </template>

            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="userData-password"
                v-model="userData.password"
                :type="passwordFieldType"
                :state="errors.length > 0 ? false : null"
                class="form-control-merge"
                name="userData-password"
                placeholder="請輸入管理者密碼"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIcon"
                  class="cursor-pointer"
                  @click="togglePassword"
                />
              </b-input-group-append>
            </b-input-group>

            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <random-generator
          ref="randomGenerator"
          department-explorer-id="randomGenerator"
          department-explorer-type="modal"
          :is-hiden-emit="false"
          :use-callback-data="true"
          @call-back-data="(item) => userData.password = item"
        />
      </b-form>
    </validation-observer>

    <template #modal-footer>
      <div class="w-100 text-right">
        <b-button
          variant="primary"
          :disabled="isBusy"
          @click="handleOk"
        >
          <span v-if="!isBusy">確認</span>

          <b-spinner
            v-else
            small
          />
        </b-button>
      </div>
    </template>

    <department-explorer-modal
      ref="departmentExplorerModal"
      department-explorer-id="departmentExplorerModal"
      :is-hiden-emit="true"
      :use-callback-data="false"
      @emit-hidden-function="resetMetaList"
    />

  </b-modal>
</template>

<script>
// API
// import { ref } from '@vue/composition-api'
// import { required } from '@validations'
// import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'

// UI
// import {
//   BForm, VBToggle, BButton, BSpinner, BFormInvalidFeedback, BFormGroup, BFormInput,
//   VBTooltip, // BInputGroupAppend, BInputGroup, BImg,
// } from 'bootstrap-vue'
// // import vSelect from 'vue-select'
// import Ripple from 'vue-ripple-directive'

// // Component
// import formValidation from '@core/comp-functions/forms/form-validation'
// // import UserExplorerModal from '@/layouts/components/Modal/user-modal/userModal.vue'
// import { useUserModal, useModalSetting } from '../useModal'
// import { useAlert } from '@/libs/mixins/index'

// API
import { ref } from '@vue/composition-api'
import { required, password } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'

// UI
import {
  BForm, VBToggle, BButton, BSpinner, BFormInvalidFeedback, BFormGroup, BFormInput, BInputGroupAppend,
  BInputGroup, BImg, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

// Component
import formValidation from '@core/comp-functions/forms/form-validation'
import DepartmentExplorerModal from '@/layouts/components/Modal/department-modal/departmentModal.vue'
import RandomGenerator from '@/layouts/components/Modal/random-modal/RandomModal.vue'
import { useUserModal, useModalSetting } from '../useModal'
import { useAlert } from '@/libs/mixins/index'

export default {
  components: {
    BImg,
    BForm,
    // BFormGroup,
    BButton,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // BFormSpinbutton,

    vSelect,
    ValidationProvider,
    ValidationObserver,
    DepartmentExplorerModal,
    RandomGenerator,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  props: {
    selectedItem: {
      type: Object,
      required: true,
    },
    updateModalId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
      password,
      missingFields: [],
      passwordFieldType: 'password',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  methods: {
    // 顯示密碼/隱藏密碼
    togglePassword() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    // 按下彈窗關閉
    handleHide(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.resetUserData()
      this.isBusy = false
      this.$nextTick(() => {
        this.$bvModal.hide(this.updateModalId)
      })
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      if (!this.userData.department_id) {
        this.useAlertToast(false, '尚未選擇部門')
        return
      }

      // 判斷必填欄位是否填寫
      const requiredFields = {
        name: '使用者名稱',
        account: '使用者帳號',
      }

      if (!this.selectedItem.id) requiredFields.password = '密碼設定'

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.userData[Object.keys(requiredFields)[i]] === null || this.userData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            lable: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].lable}不得為空`)
        return
      }

      this.isBusy = true

      if (this.selectedItem.id) {
        this.setUserUpdate({
          user_id: this.selectedItem.id,
          data: {
            ...this.userData,
          },
        })
          .then(response => {
            this.useHttpCodeAlert(response)
            this.$emit('reset-table', { id: this.selectedItem.id, ...this.userData })
            this.resetUserData()
            this.$nextTick(() => {
              this.$bvModal.hide(this.updateModalId)
            })
            this.isBusy = false
          })
          .catch(error => {
            this.useHttpCodeAlert(error.response)
            this.isBusy = false
          })
      } else {
        this.setUserCreate({
          ...this.userData,
        })
          .then(response => {
            this.useHttpCodeAlert(response)
            this.$emit('reset-table', { id: this.selectedItem.id, ...this.userData })
            this.resetUserData()
            this.$nextTick(() => {
              this.$bvModal.hide(this.updateModalId)
            })
            this.isBusy = false
          })
          .catch(error => {
            this.useHttpCodeAlert(error.response)
            this.isBusy = false
          })
      }
    },

    // (重新)獲取Meta
    resetMetaList() {
      this.getMetaListData()
        .then(response => {
          const { departments } = response.data.data
          this.departmentsOptions = departments
          const findObject = departments.find(el => el.id === this.selectedItem.department_id)

          if (findObject === undefined) {
            this.userData.department_id = null
          }
        })
    },

    // (獲取)資料
    getData() {
      this.getMetaListData()
        .then(response => {
          const { departments } = response.data.data
          this.departmentsOptions = departments

          const resolveData = {
            ...this.syncObject(this.blankUserData, this.selectedItem),
          }

          const findObject = departments.find(el => el.id === this.selectedItem.department_id)

          if (findObject === undefined) {
            resolveData.department_id = null
          }
          this.userData = resolveData
          this.$bvModal.show(this.updateModalId)
        })
        .catch(() => {
          const resolveData = {
            ...this.syncObject(this.blankUserData, this.selectedItem),
          }
          this.userData = resolveData
          this.$bvModal.show(this.updateModalId)
        })
      // const resolveData = {
      //   ...this.syncObject(this.blankUserData, this.selectedItem),
      // }
      // this.userData = resolveData
      // this.$bvModal.show(this.updateModalId)
    },
  },
  setup() {
    localize('tw')

    const {
      setUserCreate,
      setUserUpdate,
      statusOptions,
    } = useUserModal()

    const {
      isBusy,
      syncObject,
      getMetaListData,
      departmentsOptions,
    } = useModalSetting()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const blankUserData = {
      name: null,
      department_id: null,
      account: null,
      password: null,
      status: 0,
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))

    const resetUserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
      isBusy.value = false
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetUserData)

    return {
      isBusy,

      userData,
      statusOptions,
      blankUserData,
      setUserCreate,
      setUserUpdate,
      getMetaListData,
      departmentsOptions,

      resetUserData,
      refFormObserver,
      getValidationState,
      resetForm,

      useHttpCodeAlert,
      useAlertToast,
      syncObject,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.model-header {
  color: white;
  padding: 5px 0;
}
</style>
