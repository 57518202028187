<template>
  <div>
    <!-- 品牌 -->
    <b-form-group label-for="brand_id">
      <template #label>
        <div class="d-flex justify-content-between align-items-center">
          <label class="mb-0">
            品牌
          </label>

          <span>
            <div
              v-b-tooltip.hover.v-secondary
              title="品牌設定"
              class="d-flex align-items-center actions-link-btn-sm"
              @click="() => { $refs.brandExplorerAddModal.getData(null) }"
            >
              <!-- @click="() => { $refs.brandExplorerMainModal.getData() }" -->
              <b-img
                src="/admin/images/table/settings.svg"
                class="actions-link-btn-image"
                rounded
              />
            </div>
          </span>
        </div>
      </template>

      <v-select
        v-model="deviceDataItem.extends.brand_id"
        :options="brandsOptions"
        :clearable="false"
        label="name"
        :reduce="option => option.id"
        placeholder="請選擇品牌"
      >
        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            沒有<em>{{ search }}</em> 的相關結果
          </template>

          <div v-else>
            <small>暫無資料</small>
          </div>
        </template>
      </v-select>
    </b-form-group>

    <brand-explorer-modal
      ref="brandExplorerAddModal"
      brand-explorer-id="brandExplorerAddModal"
      :is-hiden-emit="true"
      :use-callback-data="false"
      @emit-hidden-function="resetMetaList"
    />
  </div>
</template>

<script>
// API
import { ref } from '@vue/composition-api'
import { required, password } from '@validations'
import { localize } from 'vee-validate'
// ValidationProvider, ValidationObserver,

// UI
import {
  VBToggle, BFormGroup,
  // BForm, BButton, BSpinner, BFormInvalidFeedback, BFormGroup, BFormInput, BFormTextarea,
  BImg, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import VueSlider from 'vue-slider-component'
import language from 'flatpickr/dist/l10n/zh-tw'
// import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'

// Component
import formValidation from '@core/comp-functions/forms/form-validation'
import BrandExplorerModal from '@/layouts/components/Modal/brand-modal/brandModal.vue'
import { useDeviceCPUList, useDeviceSetting } from '../../../useDevice'
import { useAlert } from '@/libs/mixins/index'

export default {
  components: {
    BImg,
    BFormGroup,
    // BForm,
    // // BFormGroup,
    // BButton,
    // BSpinner,
    // BFormInvalidFeedback,
    // BFormGroup,
    // BFormInput,
    // BFormTextarea,
    // BFormSpinbutton,

    vSelect,
    // VueSlider,
    // flatPickr,
    // ValidationProvider,
    // ValidationObserver,
    BrandExplorerModal,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  props: {
    deviceDataItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      password,
      missingFields: [],
      maxChar: 255,
    }
  },
  mounted() {
    this.resetMetaList()
  },
  methods: {
    // (重新)獲取Meta
    resetMetaList() {
      this.getMetaListData()
        .then(response => {
          const { brands } = response.data.data
          this.brandsOptions = brands
          const findObject = brands.find(el => el.id === this.deviceDataItem.brand_id)

          if (findObject === undefined) {
            this.deviceData.brand_id = null
          }
        })
    },
  },
  setup() {
    localize('tw')

    const {
      isBusy,
      blankCPUData,
      setDeviceCPUCreate,
      setDeviceCPUUpdate,
    } = useDeviceCPUList()

    const {
      getMetaListData,
      brandsOptions,
      syncObject,
    } = useDeviceSetting()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const deviceData = ref(JSON.parse(JSON.stringify(blankCPUData)))

    const reseDeviceData = () => {
      deviceData.value = JSON.parse(JSON.stringify(blankCPUData))
      isBusy.value = false
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(reseDeviceData)

    const dateConfig = {
      locale: language.zh_tw,
      dateFormat: 'Y-m-d',
      allowInput: true,
      static: true,
    }

    return {
      isBusy,
      dateConfig,

      deviceData,
      brandsOptions,
      blankCPUData,
      setDeviceCPUCreate,
      setDeviceCPUUpdate,
      getMetaListData,

      reseDeviceData,
      refFormObserver,
      getValidationState,
      resetForm,

      useHttpCodeAlert,
      useAlertToast,
      syncObject,
    }
  },
}
</script>
