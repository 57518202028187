import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // (查詢)設備列表
    getDeviceList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/devices', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)設備
    setDeviceCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/devices', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)設備
    setDeviceUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/devices/${resolveData.device_id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)設備
    setDeviceDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/devices/${resolveData.device_id}`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // -------------------------------------------------------------------------------------------
    // (查詢)設備-主機管理列表
    getDevicePCList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/devices/pc', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (查詢)單一設備-主機管理列表
    getDevicePCData(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/devices/pc/${resolveData.pc_id}`, { headers: { 'Access-Token': useJwt.getToken() }, params: resolveData.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)設備-主機管理
    setDevicePCUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/devices/pc/${resolveData.pc_id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // -------------------------------------------------------------------------------------------
    // (查詢)設備-CPU列表
    getDeviceCPUList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/devices/cpu', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)設備-CPU管理
    setDeviceCPUCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/devices/cpu', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)設備-CPU管理
    setDeviceCPUUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/devices/cpu/${resolveData.cpu_id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)設備-CPU管理
    setDeviceCPUDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/devices/cpu/${resolveData.cpu_id}`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // -------------------------------------------------------------------------------------------
    // (查詢)設備-記憶體列表
    getDeviceMemoryList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/devices/memories', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)設備-記憶體管理
    setDeviceMemoryCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/devices/memories', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)設備-記憶體管理
    setDeviceMemoryUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/devices/memories/${resolveData.memory_id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)設備-記憶體管理
    setDeviceMemoryDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/devices/memories/${resolveData.memory_id}`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // -------------------------------------------------------------------------------------------
    // (查詢)設備-硬碟列表
    getDeviceDiskList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/devices/hard-disk', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (新增)設備-硬碟管理
    setDeviceDiskCreate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/admin/devices/hard-disk', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)設備-硬碟管理
    setDeviceDiskUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/devices/hard-disk/${resolveData.disk_id}`, {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData.data,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)設備-硬碟管理
    setDeviceDiskDelete(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios(`/admin/devices/hard-disk/${resolveData.disk_id}`, {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
