import { ref, watch } from '@vue/composition-api'
import {
  useTableComponent, useCommenSettings, useAlert,
} from '@/libs/mixins/index'
import store from '@/store'
// import router from '@/router'

export const useModalSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const isBusy = ref(false)

  const ui = {}

  const departmentsOptions = ref([])
  const brandsOptions = ref([])
  const getMetaListData = () => store.dispatch('api/getMetaList')

  return {
    ui,
    isBusy,

    getMetaListData,
    departmentsOptions,
    brandsOptions,

    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  }
}

export const useDepartmentModal = () => {
  const tableData = ref([])
  const isTableBusy = ref(true)

  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
  } = useTableComponent()

  const {
    useHttpCodeAlert,
  } = useAlert()

  tableColumns.value = [
    { label: '#編號', key: 'id' },
    { label: '部門名稱', key: 'name' },
    { label: '設定', key: 'use' },
    { label: '動作', key: 'actions' },
  ]

  const setDepartmentDelete = (...arg) => store.dispatch('admin-department-modal/setDepartmentDelete', ...arg)
  const setDepartmentCreate = (...arg) => store.dispatch('admin-department-modal/setDepartmentCreate', ...arg)
  const setDepartmentUpdate = (...arg) => store.dispatch('admin-department-modal/setDepartmentUpdate', ...arg)

  const getDepartmentListData = () => {
    isTableBusy.value = true
    store.dispatch('admin-department-modal/getDepartmentList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        tableData.value = data
        totalNum.value = total
        isTableBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else getDepartmentListData()
  }

  watch([currentPage, perPage, searchQuery], () => {
    getDepartmentListData()
  })

  return {
    tableData,
    isTableBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    refetchTable,

    setDepartmentDelete,
    setDepartmentCreate,
    setDepartmentUpdate,
    getDepartmentListData,
    useHttpCodeAlert,
  }
}

export const useBrandModal = () => {
  const tableData = ref([])
  const isTableBusy = ref(true)

  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
  } = useTableComponent()

  const {
    useHttpCodeAlert,
  } = useAlert()

  tableColumns.value = [
    { label: '#編號', key: 'id' },
    { label: '部門名稱', key: 'name' },
    { label: '設定', key: 'use' },
    { label: '動作', key: 'actions' },
  ]

  const setBrandDelete = (...arg) => store.dispatch('admin-brand-modal/setBrandDelete', ...arg)
  const setBrandCreate = (...arg) => store.dispatch('admin-brand-modal/setBrandCreate', ...arg)
  const setBrandUpdate = (...arg) => store.dispatch('admin-brand-modal/setBrandUpdate', ...arg)

  const getBrandListData = () => {
    isTableBusy.value = true
    store.dispatch('admin-brand-modal/getBrandList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        tableData.value = data
        totalNum.value = total
        isTableBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else getBrandListData()
  }

  watch([currentPage, perPage, searchQuery], () => {
    getBrandListData()
  })

  return {
    tableData,
    isTableBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    refetchTable,

    setBrandDelete,
    setBrandCreate,
    setBrandUpdate,
    getBrandListData,
    useHttpCodeAlert,
  }
}

export const useAdminBrandModal = () => {
  const tableData = ref([])
  const isTableBusy = ref(true)

  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
  } = useTableComponent()

  const {
    useHttpCodeAlert,
  } = useAlert()

  tableColumns.value = [
    { label: '#編號', key: 'id' },
    { label: '部門名稱', key: 'name' },
    { label: '設定', key: 'use' },
    { label: '動作', key: 'actions' },
  ]

  const setBrandDelete = (...arg) => store.dispatch('admin-brand-modal/setBrandDelete', ...arg)
  const setBrandCreate = (...arg) => store.dispatch('admin-brand-modal/setBrandCreate', ...arg)
  const setBrandUpdate = (...arg) => store.dispatch('admin-brand-modal/setBrandUpdate', ...arg)

  const getBrandListData = () => {
    isTableBusy.value = true
    store.dispatch('admin-brand-modal/getBrandList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        tableData.value = data
        totalNum.value = total
        isTableBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else getBrandListData()
  }

  watch([currentPage, perPage, searchQuery], () => {
    getBrandListData()
  })

  return {
    tableData,
    isTableBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    refetchTable,

    setBrandDelete,
    setBrandCreate,
    setBrandUpdate,
    getBrandListData,
    useHttpCodeAlert,
  }
}

export const useTodoTypeModal = () => {
  const tableData = ref([])
  const isTableBusy = ref(true)
  const searchDepartment = ref(null)

  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
  } = useTableComponent()

  const {
    useHttpCodeAlert,
  } = useAlert()

  tableColumns.value = [
    { label: '#編號', key: 'id' },
    { label: '名稱', key: 'name' },
    { label: '設定', key: 'use' },
    { label: '動作', key: 'actions' },
  ]

  const setTodoTypeDelete = (...arg) => store.dispatch('admin-todo-modal/setTodoTypeDelete', ...arg)
  const setTodoTypeCreate = (...arg) => store.dispatch('admin-todo-modal/setTodoTypeCreate', ...arg)
  const setTodoTypeUpdate = (...arg) => store.dispatch('admin-todo-modal/setTodoTypeUpdate', ...arg)

  const getTodoTypeListData = () => {
    isTableBusy.value = true
    // 整理filters
    let resolveFilters = ''

    if (searchDepartment.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};department_id: ${searchDepartment.value}`
      } else resolveFilters = `department_id: ${searchDepartment.value}`
    }
    store.dispatch('admin-todo-modal/getTodoTypeList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      filters: resolveFilters,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        tableData.value = data
        totalNum.value = total
        isTableBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else getTodoTypeListData()
  }

  watch([currentPage, perPage, searchQuery, searchDepartment], () => {
    getTodoTypeListData()
  })

  return {
    tableData,
    isTableBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    searchDepartment,
    refetchTable,

    setTodoTypeDelete,
    setTodoTypeCreate,
    setTodoTypeUpdate,
    getTodoTypeListData,
    useHttpCodeAlert,
  }
}

export const useTodoExecutionTypeModal = () => {
  const tableData = ref([])
  const isTableBusy = ref(true)
  const searchDepartment = ref(null)

  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
  } = useTableComponent()

  const {
    useHttpCodeAlert,
  } = useAlert()

  tableColumns.value = [
    { label: '#編號', key: 'id' },
    { label: '名稱', key: 'name' },
    { label: '設定', key: 'use' },
    { label: '動作', key: 'actions' },
  ]

  const setTodoExecutionTypeDelete = (...arg) => store.dispatch('admin-todo-modal/setTodoExecutionDelete', ...arg)
  const setTodoExecutionTypeCreate = (...arg) => store.dispatch('admin-todo-modal/setTodoExecutionCreate', ...arg)
  const setTodoExecutionTypeUpdate = (...arg) => store.dispatch('admin-todo-modal/setTodoExecutionUpdate', ...arg)

  const getTodoExecutionTypeListData = () => {
    isTableBusy.value = true
    // 整理filters
    let resolveFilters = ''

    if (searchDepartment.value) {
      if (resolveFilters) {
        resolveFilters = `${resolveFilters};department_id: ${searchDepartment.value}`
      } else resolveFilters = `department_id: ${searchDepartment.value}`
    }
    store.dispatch('admin-todo-modal/getTodoExecutionList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      filters: resolveFilters,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        tableData.value = data
        totalNum.value = total
        isTableBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else getTodoExecutionTypeListData()
  }

  watch([currentPage, perPage, searchQuery, searchDepartment], () => {
    getTodoExecutionTypeListData()
  })

  return {
    tableData,
    isTableBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    searchDepartment,
    refetchTable,

    setTodoExecutionTypeDelete,
    setTodoExecutionTypeCreate,
    setTodoExecutionTypeUpdate,
    getTodoExecutionTypeListData,
    useHttpCodeAlert,
  }
}

export const useOpenAIModal = () => {
  const tableData = ref([])
  const isTableBusy = ref(true)

  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
  } = useTableComponent()

  const {
    useHttpCodeAlert,
  } = useAlert()

  const rolesOptions = ref([])

  tableColumns.value = [
    { label: '#編號', key: 'id' },
    { label: 'AI名稱', key: 'role_name' },
    { label: '選擇', key: 'use' },
    { label: '動作', key: 'actions' },
  ]

  const setOpenAIDelete = (...arg) => store.dispatch('admin-openAI-modal/setOpenAIDelete', ...arg)
  const setOpenAICreate = (...arg) => store.dispatch('admin-openAI-modal/setOpenAICreate', ...arg)
  const setOpenAIUpdate = (...arg) => store.dispatch('admin-openAI-modal/setOpenAIUpdate', ...arg)

  const getOpenAIListData = () => {
    isTableBusy.value = true
    store.dispatch('admin-openAI-modal/getOpenAIList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      _: Date.now(),
    })
      .then(response => {
        const { data } = response.data
        tableData.value = data
        totalNum.value = data.length
        isTableBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else getOpenAIListData()
  }

  watch([currentPage, perPage, searchQuery], () => {
    getOpenAIListData()
  })

  return {
    isTableBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    refetchTable,

    tableData,
    rolesOptions,

    setOpenAIDelete,
    setOpenAICreate,
    setOpenAIUpdate,
    getOpenAIListData,
    useHttpCodeAlert,
  }
}

export const useUserModal = () => {
  const tableData = ref([])
  const isTableBusy = ref(true)

  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
  } = useTableComponent()

  const {
    useHttpCodeAlert,
  } = useAlert()

  tableColumns.value = [
    { label: '#編號', key: 'id' },
    { label: '使用者', key: 'name' },
    { label: '部門', key: 'department' },
    { label: '設定', key: 'use' },
    { label: '動作', key: 'actions' },
  ]

  const statusOptions = [{
    label: '停用', value: 0,
  }, {
    label: '啟用', value: 1,
  }]

  const setUserDelete = (...arg) => store.dispatch('admin-user-modal/setUserDelete', ...arg)
  const setUserCreate = (...arg) => store.dispatch('admin-user-modal/setUserCreate', ...arg)
  const setUserUpdate = (...arg) => store.dispatch('admin-user-modal/setUserUpdate', ...arg)

  const getUserListData = () => {
    isTableBusy.value = true
    store.dispatch('admin-user-modal/getUserList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        tableData.value = data
        totalNum.value = total
        isTableBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else getUserListData()
  }

  watch([currentPage, perPage, searchQuery], () => {
    getUserListData()
  })

  return {
    tableData,
    isTableBusy,
    statusOptions,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    refetchTable,

    setUserDelete,
    setUserCreate,
    setUserUpdate,
    getUserListData,
    useHttpCodeAlert,
  }
}

export const usePlaceModal = () => {
  const tableData = ref([])
  const isTableBusy = ref(true)

  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
  } = useTableComponent()

  const {
    useHttpCodeAlert,
  } = useAlert()

  tableColumns.value = [
    { label: '#編號', key: 'id' },
    { label: '地點名稱', key: 'name' },
    { label: '設定', key: 'use' },
    { label: '動作', key: 'actions' },
  ]

  const setPlaceDelete = (...arg) => store.dispatch('admin-place-modal/setPlaceDelete', ...arg)
  const setPlaceCreate = (...arg) => store.dispatch('admin-place-modal/setPlaceCreate', ...arg)
  const setPlaceUpdate = (...arg) => store.dispatch('admin-place-modal/setPlaceUpdate', ...arg)

  const getPlaceListData = () => {
    isTableBusy.value = true
    store.dispatch('admin-place-modal/getPlaceList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        tableData.value = data
        totalNum.value = total
        isTableBusy.value = false
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else getPlaceListData()
  }

  watch([currentPage, perPage, searchQuery], () => {
    getPlaceListData()
  })

  return {
    tableData,
    isTableBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    refetchTable,

    setPlaceDelete,
    setPlaceCreate,
    setPlaceUpdate,
    getPlaceListData,
    useHttpCodeAlert,
  }
}
