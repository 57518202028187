<template>
  <div>
    <!-- IP位置 -->
    <validation-provider
      #default="{ errors }"
      name="IP位置"
      rules="required"
    >
      <b-form-group label-for="assigned_ip">
        <template #label>
          <label class="mb-0">
            IP位置
            <span class="text-danger">*</span>
          </label>
        </template>

        <b-form-input
          id="assigned_ip"
          v-model="deviceDataItem.extends.pc_info.assigned_ip"
          :state="errors.length > 0 ? false : null"
          trim
          placeholder="192.168.1.0"
        />

        <b-form-invalid-feedback>
          {{ errors[0] }}
        </b-form-invalid-feedback>
      </b-form-group>
    </validation-provider>

    <!-- <div>
      <label>主機說明</label>
      <b-form-textarea
        v-model="deviceDataItem.extends.pc_info.description"
        :placeholder="'請輸入主機說明'"
        :state="deviceDataItem.extends.pc_info.description.length <= maxChar ? null : false"
        rows="5"
      />
      <small
        class="textarea-counter-value float-right"
        :class="deviceDataItem.extends.pc_info.description.length > maxChar ? 'bg-danger' : ''"
      >
        <span class="char-count">{{ deviceDataItem.extends.pc_info.description.length }}</span> / {{ maxChar }}
      </small>
    </div> -->
  </div>
</template>

<script>
// API
import { required } from '@validations'
import { ValidationProvider, localize } from 'vee-validate'

// UI
import {
  VBToggle, BFormGroup, BFormInvalidFeedback, BFormInput, // BFormTextarea,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormInvalidFeedback,
    BFormInput,
    // BFormTextarea,
    ValidationProvider,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    deviceDataItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      missingFields: [],
      maxChar: 255,
    }
  },
  setup() {
    localize('tw')
  },
}
</script>
